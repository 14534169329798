var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dn-search-bar" }, [
    _c("i", { staticClass: "dn-icon dn-icon--search dn-search-bar__icon" }),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.search,
          expression: "search",
        },
      ],
      ref: "input",
      staticClass: "dn-search-bar__input",
      attrs: { placeholder: "Search by packs, videos, artists, etc." },
      domProps: { value: _vm.search },
      on: {
        focus: _vm.handleFocus,
        blur: _vm.handleFocus,
        input: [
          function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          },
          _vm.update,
        ],
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }